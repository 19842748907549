import { useTranslations } from "next-intl";
import { NextSeo } from "next-seo";
import T from "prop-types";

import withPageStaticProps from "helpers/withPageStaticProps";
import { CMS_API_TOKEN, CMS_API_URL, ENV_IS_DEVELOPMENT } from "config/system";
import Features from "containers/Landingpage/Features";
import Comparison from "containers/Landingpage/Comparison";
import { getProducts } from "api/product";
import {
  useFormattedShortViewProducts,
  generateShortViewProductData,
} from "helpers/products";
import Section from "components/Layout/Section";
import CarousellView from "components/molecules/ProductDisplay/CarousellView";
import { Box } from "components/MUI";
import theme from "config/theme";
import ContactUs from "components/sections/ContactUs";
import HighlightingSteps from "components/sections/HighlightingSteps";
import CashflowExplanation from "containers/Landingpage/CashflowExplanation";
import { getEnrichedCategories } from "helpers/categories";
import GridButtonView from "components/molecules/CategoryDisplay/GridButtonView/GridButtonView";
import { Text } from "components/atoms/Typography";
import Link from "components/Link/Link";

export default function Home({
  navigationData: categories,
  products: rawProducts,
}) {
  const t = useTranslations();

  const products = useFormattedShortViewProducts(rawProducts, "product_offer");
  const enrichedCategories = getEnrichedCategories(categories, t);

  return (
    <>
      <NextSeo
        title={t("seo.title")}
        description={t("seo.description")}
        noindex={ENV_IS_DEVELOPMENT}
        nofollow={ENV_IS_DEVELOPMENT}
      />
      <Features />
      <HighlightingSteps />
      {categories?.length > 0 && (
        <Section
          title={t("product_offer.h2")}
          titleStyleProps={{
            align: "center",
            sx: { mb: { xs: theme.spacing(2), md: theme.spacing(4) } },
          }}
        >
          <GridButtonView mobile categories={enrichedCategories} />
          <Text
            variant="h3"
            as="p"
            sx={{ mt: theme.spacing(10), mb: theme.spacing(5) }}
            display={{ xs: "none", md: "block" }}
          >
            {t("product_offer.h3")}
          </Text>
          <CarousellView products={products} />
          <Box textAlign="center" sx={{ mt: theme.spacing(5) }}>
            <Link
              type="button"
              variant="contained"
              size="large"
              disableElevation
              href={t("links.all_products.link")}
            >
              {t("product_offer.btn_all_products")}
            </Link>
          </Box>
        </Section>
      )}
      <Comparison />
      <CashflowExplanation />
      <ContactUs
        variant="light"
        anchor={t("contact_anchor")}
        contactUs={{ title: t("contact_us.h2") }}
        contactBtn={{
          link: t("links.faq.link"),
          linkText: t("contact_us.btn_faq_link"),
        }}
        emailBtn={{
          text: t("contact_us.email_text"),
          email: t("contact_us.btn_email"),
          linkText: t("contact_us.btn_email"),
        }}
        phoneBtn={{
          text: t("contact_us.phone_text"),
          number: t("contact_us.btn_phone"),
          linkText: t("contact_us.btn_phone"),
        }}
      />
    </>
  );
}

Home.propTypes = {
  products: T.arrayOf(T.shape({})).isRequired,
  navigationData: T.arrayOf(T.shape({})).isRequired,
};

export const getStaticProps = withPageStaticProps(
  async ({ locale, locales }, sharedPageStaticProps) => {
    const headers = {
      Authorization: `bearer ${CMS_API_TOKEN}`,
    };

    const [products] = await Promise.all([
      getProducts(
        {
          "[fields][0]": "name",
          "[fields][1]": "short_description",
          "[fields][2]": "slug",
          "[fields][3]": "lowest_price_net",
          "populate[images]": "*",
          "populate[product_group][fields][0]": "name",
          "populate[product_group][fields][1]": "slug",
          "populate[base_price][fields][0]": "currency",
          "populate[manufacturer][fields][0]": "name",
          "filters[carousell][startpage][$eq]": true,
          locale,
        },
        CMS_API_URL,
        {
          headers,
        }
      ),
    ]);

    return {
      props: {
        ...sharedPageStaticProps.props,
        products: generateShortViewProductData(products?.data || []),
        messages: {
          ...(await import(`../locales/${locale}/home.json`)).default,
          ...(await import(`../locales/${locale}/generic.json`)).default,
        },
        locale,
        locales,
      },
      revalidate: 60,
    };
  }
);
